import _defineProperty from "/var/lib/jenkins/workspace/apg.openapi/apg.fs2pro/source/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable */
var usemf = window.usemf;
var shareScopes = usemf.getShareScopes();

var localShared = require("/var/lib/jenkins/workspace/apg.openapi/apg.fs2pro/source/$_mfplugin_virtualLocalShared/index.js");

var usedShared = {};
var firstInit = true;
mergeShared(localShared, shareScopes);

function mergeShared(scopeShared) {
  var shareScopes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.usemf.getShareScopes();
  Object.keys(scopeShared).forEach(function (name) {
    var pkg = scopeShared[name];
    var _pkg$shareScope = pkg.shareScope,
        shareScope = _pkg$shareScope === void 0 ? "default" : _pkg$shareScope,
        version = pkg.version;
    if (!shareScopes[shareScope]) shareScopes[shareScope] = {};
    shareScopes[shareScope][name] = shareScopes[shareScope][name] || {};
    var pkgInfo = shareScopes[shareScope][name];
    pkgInfo[version] = pkgInfo[version] || {
      loaded: pkg.loaded,
      get: function get() {
        pkgInfo[version].loaded = 1;
        return pkg.get();
      },
      from: pkg.from,
      eager: pkg.eager
    };
  });
} // 如果usemf的scopes不是源头, 需要使用源头的scopes


export function setInitShared() {
  var scopeShared = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var scope = "default";

  if (firstInit) {
    // 是第一次container.init则使用scopeShare替代shareScopes[scope]
    firstInit = false;
    shareScopes[scope] = scopeShared;
    mergeShared(localShared, shareScopes);
  } // 非第一次container.init, 则验证多次init的scope是否一致


  var oldScope = shareScopes[scope];

  if (oldScope && oldScope !== scopeShared) {
    throw new Error("Container initialization failed as it has already been initialized with a different share scope");
  }

  Object.keys(scopeShared).forEach(function (pkg) {
    var pkgVersionMap = scopeShared[pkg];
    Object.keys(pkgVersionMap).forEach(function (version) {
      mergeShared(_defineProperty({}, pkg, _objectSpread({
        version: version
      }, pkgVersionMap[version])), shareScopes);
    });
  });
}
export function getUsedShare(pkg) {
  if (usedShared[pkg]) return usedShared[pkg];
  var useShareVersion = window.usemf.getShare(pkg, localShared[pkg]);
  usedShared[pkg] = {
    version: useShareVersion,
    shareScope: localShared[pkg].shareScope
  };
  return usedShared[pkg];
}
export function initShared() {
  var plugin = window["__mfplugin__apg_app"];

  if (!plugin.initSharedPromise) {
    plugin.initSharedPromise = Promise.all([plugin.wpmjs.getMFContainer("copytrade_app", undefined).then(function (container) {
      return container.init(window.usemf.getShareScopes()["default"]);
    })]);
  }

  return plugin.initSharedPromise;
}
export function getShareScopes() {
  return usemf.getshareScopes();
}